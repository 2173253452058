<template>
  <div>
    <table style="width: 100%" v-if="selectedSetting?.format === 'A4'">
      <tr>
        <td class="doc-patient" :style="{ width: '25%' }">
          <b>Paciente:</b> Nome do paciente
        </td>
        <td
          v-if="selectedSetting?.show_patient_cpf"
          class="doc-patient"
          :style="{ width: '25%' }"
        >
          <b>CPF:</b> CPF do paciente
        </td>
        <td
          v-if="selectedSetting?.show_patient_rg"
          class="doc-patient"
          :style="{ width: '25%' }"
        >
          <b>RG:</b> RG do paciente
        </td>
        <td
          v-if="selectedSetting?.show_patient_health_plan"
          class="doc-patient"
          :style="{ width: '25%' }"
        >
          <b>Convênio:</b> Convênio
        </td>
      </tr>
      <tr>
        <td
          v-if="selectedSetting?.show_patient_age"
          class="doc-patient"
          :style="{ width: '25%' }"
        >
          <b>Idade:</b> 00 anos
        </td>
        <td
          v-if="selectedSetting?.show_patient_gender"
          class="doc-patient"
          :style="{ width: '25%' }"
        >
          <b>Sexo:</b> Sexo do paciente
        </td>
        <td
          v-if="selectedSetting?.show_patient_medical_record"
          class="doc-patient"
          :style="{ width: '25%' }"
        >
          <b>N. prontuário:</b> 123456
        </td>
        <td
          v-if="selectedSetting?.show_exam_date"
          class="doc-patient"
          :style="{ width: '25%' }"
        >
          <b>Data:</b> 01/01/2000
        </td>
      </tr>
    </table>
    <table style="width: 100%" v-else-if="selectedSetting?.format === 'A5'">
      <tr>
        <td class="doc-patient" :style="{ width: '50%' }">
          <b>Paciente:</b> Nome do paciente
        </td>
        <td
          v-if="selectedSetting?.show_patient_cpf"
          class="doc-patient"
          :style="{ width: '50%' }"
        >
          <b>CPF:</b> CPF do paciente
        </td>
      </tr>
      <tr>
        <td
          v-if="selectedSetting?.show_patient_rg"
          class="doc-patient"
          :style="{ width: '50%' }"
        >
          <b>RG:</b> RG do paciente
        </td>
        <td
          v-if="selectedSetting?.show_patient_health_plan"
          class="doc-patient"
          :style="{ width: '50%' }"
        >
          <b>Convênio:</b> Convênio
        </td>
      </tr>
      <tr>
        <td
          v-if="selectedSetting?.show_patient_age"
          class="doc-patient"
          :style="{ width: '50%' }"
        >
          <b>Idade:</b> 00 anos
        </td>
        <td
          v-if="selectedSetting?.show_patient_gender"
          class="doc-patient"
          :style="{ width: '50%' }"
        >
          <b>Sexo:</b> Sexo do paciente
        </td>
      </tr>
      <tr>
        <td
          v-if="selectedSetting?.show_patient_medical_record"
          class="doc-patient"
          :style="{ width: '50%' }"
        >
          <b>N. prontuário:</b> 123456
        </td>
        <td
          v-if="selectedSetting?.show_exam_date"
          class="doc-patient"
          :style="{ width: '50%' }"
        >
          <b>Data:</b> 01/01/2000
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'PatientDataPreview',
  props: {
    selectedSetting: Object,
    parseSize: Function
  }
}
</script>

<style lang="scss" scoped>
.doc-patient {
  color: var(--type-active);
}
</style>
